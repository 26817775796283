<template>
    <!-- 产品需求---成果转化详情页 -->
    <div class="demandResultDetail">
        <Header></Header>
        <HeadNav></HeadNav>
        <div class="minkave-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- <div class="index-headImg">
                <div class="index-headImg-left">
                    <img src="../../../assets/pc/Index/headL.png" alt="" />
                </div>
                <div class="index-headImg-right">
                    <img src="../../../assets/pc/Index/headR.png" alt="" />
                </div>
            </div> -->

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <!-- <div class="survey-title-cn">成果转化详情页</div> -->
                    <!-- <div class="survey-title-sn">About the Institute</div> -->
                </div>

                <!-- <div style="font-size: 30px">成果转换详情页</div> -->
                <!-- <ul>
                    <li
                        :key="index"
                        v-for="(item, index) in machiningTap"
                        @click="machiningTapEv(index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li>
                </ul> -->
            </div>

            <!-- 内容区域 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <!-- 内容区域左侧 -->
                    <div class="survey-content-left">
                        <!-- 左侧顶部 -->
                        <div class="detail-top">
                            <!-- 左侧顶部---上 -->
                            <div class="detail-title-top">
                                <div>
                                    <div>
                                        成果名称：{{ outcomedetailDe.title }}
                                    </div>
                                    <div>
                                        评价单位：{{
                                            outcomedetailDe.authenticate
                                        }}
                                    </div>
                                    <div>
                                        完成作者：{{ outcomedetailDe.author }}
                                    </div>
                                    <div>
                                        应用领域：{{
                                            outcomedetailDe.usedomain
                                        }}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        联系电话：{{ outcomedetailDe.stage }}
                                    </div>
                                    <div>
                                        转让方式：{{ outcomedetailDe.makeway }}
                                    </div>
                                    <div>
                                        归属单位：{{ outcomedetailDe.company }}
                                    </div>
                                    <div>
                                        <!-- {{ outcomedetailDe.money }} -->
                                        成果价格：**
                                    </div>
                                </div>
                            </div>
                            <!-- 左侧顶部---下 -->
                            <!-- <div class="detail--title-bottom">
                                {{ outcomedetailDe.usedomain }}
                            </div> -->
                        </div>

                        <!-- 左侧底部 -->
                        <div class="detail-bottom">
                            <!-- <h7>中国行业化成果</h7> -->
                            <div v-html="outcomedetailDe.content"></div>
                        </div>
                    </div>

                    <!-- 内容区域右侧 -->
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //右侧
import Left3 from "@/components/Science/Left3.vue"; //左侧
import HeadNav from "@/components/Nav.vue"; //导航
import { outcomedetail, needOutcomedetail } from "../../../network/data";
import Advertising from "@/components/Advertising.vue"; //广告图

export default {
    // 组件名称
    name: "demand",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 成果课题id
            outcomeId: "",

            // 成果课题详情页数据
            outcomedetailDe: {},
            // 当前tap索引
            // currentIndex: 2,

            // 技术难题左侧tap索引
            currentPuzzle: 0,

            // 关于学会tap数据
            machiningTap: [
                {
                    name: "技术难题",
                },
                {
                    name: "成果转化",
                },
                {
                    name: "就业招聘",
                },
                {
                    name: "招商引资",
                },
                {
                    name: "产学研合作",
                },
            ],
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query) {
            this.outcomeId = this.$route.query.id;
            // type==1助企名家成果转化页，type==2需求成果转化页
            if (this.$route.query.type == 1) {
                this.outcomedetailRe();
            } else {
                this.needOutcomedetailRe();
            }
        }
    },
    methods: {
        // 获取助企名家-成果课题详情页数据
        outcomedetailRe() {
            let data = {
                id: this.outcomeId,
            };
            outcomedetail(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data, "成果课题");
                    this.outcomedetailDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取需求成果转化页
        needOutcomedetailRe() {
            let data = {
                id: this.outcomeId,
            };
            needOutcomedetail(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.outcomedetailDe = res.data.info;
                } else {
                    this.message(res.msg);
                }
            });
        },
        machiningTapEv(index) {
            // this.surveyData = [];
            this.currentIndex = index;
        },

        // 技术难题分页点击事件
        pageChange2() {
            conosle.log("暂未设置");
        },

        // 技术难题左侧tap点击事件
        puzzleEv(index) {
            this.currentPuzzle = index;
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Right,
        Left3,
        Advertising,
        HeadNav,
    },
};
</script>

<style lang="scss">
// .demandResultDetail {
//     width: 100%;

//     .minkave-wrap {
//         width: 70%;
//         // height: 1000px;
//         margin: 0 auto;
//         // margin-top: 35px;
//         margin-bottom: 40px;
//         // border: 1px solid red;

//         /*顶部图片start*/
//         .index-headImg {
//             position: relative;
//             z-index: 0;
//             width: 100%;
//             height: 50px;
//             margin-top: 15px;
//             display: flex;
//             justify-content: space-between;

//             // 左侧图片
//             .index-headImg-left {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }

//             // 右侧图片
//             .index-headImg-right {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }
//         }
//         /*顶部图片end*/

//         /*关于学会tap start*/
//         .survey-nav {
//             display: flex;
//             align-items: center;
//             width: 100%;
//             padding: 10px 0;
//             box-sizing: border-box;
//             border-bottom: 0.5px solid #e4e4e4;

//             .survey-nav-title {
//                 width: 20%;
//                 display: flex;
//                 align-items: center;
//                 // border: 1px solid red;

//                 .survey-title-cn {
//                     font-size: 10px;
//                     color: #0056b0;
//                     margin-right: 15px;
//                 }

//                 .survey-title-sn {
//                     height: 20px;
//                     font-size: 6px;
//                     color: #fff;
//                     padding: 0 6px;
//                     background-color: #0056b0;
//                 }
//             }
//         }
//         /*关于学会tap end*/

//         /*内容区域 start*/
//         .survey-cont {
//             width: 100%;

//             .survey-content {
//                 display: flex;
//                 width: 100%;
//                 // height: 300px;
//                 // border: 1px solid red;

//                 // 内容区域左侧1(技术难题)
//                 .survey-content-left {
//                     display: flex;
//                     flex-direction: column;
//                     width: 68%;
//                     height: 100%;
//                     // border: 1px solid blue;

//                     // 左侧顶部
//                     .detail-top {
//                         font-size: 9px;
//                         padding: 15px 0;
//                         box-sizing: border-box;
//                         border-bottom: 0.5px solid #e6e6e6;

//                         //左侧顶部---上
//                         .detail-title-top {
//                             display: flex;
//                             justify-content: space-between;
//                             // margin-bottom: 5px;

//                             > div:nth-child(1) {
//                                 width: 48%;
//                                 div {
//                                     margin-bottom: 7px;
//                                 }
//                             }

//                             > div:nth-child(2) {
//                                 width: 48%;
//                                 div {
//                                     margin-bottom: 7px;
//                                 }
//                             }
//                             > div:nth-child(3) {
//                                 width: 30%;
//                                 div {
//                                     margin-bottom: 7px;
//                                 }
//                             }
//                         }
//                     }

//                     // 左侧底部
//                     .detail-bottom {
//                         margin-top: 20px;
//                         height: 660px;
//                         overflow-y: auto;

//                         &::-webkit-scrollbar {
//                             width: 6px;
//                             height: 6px;
//                         }
//                         // 滚动条样式 start
//                         // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
//                         &::-webkit-scrollbar-button {
//                             display: none;
//                         }
//                         // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
//                         &::-webkit-scrollbar-thumb {
//                             background: rgba(144, 147, 153, 0.3);
//                             cursor: pointer;
//                             border-radius: 4px;
//                         }
//                         // 边角，即两个滚动条的交汇处
//                         &::-webkit-scrollbar-corner {
//                             display: none;
//                         }
//                         // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
//                         &::-webkit-resizer {
//                             display: none;
//                         }
//                         // 滚动条样式 end

//                         > div:nth-child(1) {
//                             text-align: center;
//                             font-size: 12px;
//                             margin-bottom: 10px;
//                         }

//                         > h7 {
//                             display: block;
//                             text-align: center;
//                             font-size: 20px;
//                         }
//                         > div {
//                             font-size: 10px;
//                         }
//                     }
//                 }

//                 // 内容区域右侧
//                 .survey-content-right {
//                     margin-left: auto;
//                     width: 30%;
//                     // border: 1px solid red;
//                 }
//             }
//         }

//         /*内容区域 end*/
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) {
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .demandResultDetail {
//         width: 100%;

//         .minkave-wrap {
//             width: 80%;
//             // height: 1000px;
//             margin: 0 auto;
//             // margin-top: 35px;
//             margin-bottom: 40px;
//             // border: 1px solid red;

//             /*顶部图片start*/
//             .index-headImg {
//                 position: relative;
//                 z-index: 0;
//                 width: 100%;
//                 height: 50px;
//                 margin-top: 15px;
//                 display: flex;
//                 justify-content: space-between;

//                 // 左侧图片
//                 .index-headImg-left {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧图片
//                 .index-headImg-right {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//             /*顶部图片end*/

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 1px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 20%;
//                     display: flex;
//                     align-items: center;
//                     // border: 1px solid red;

//                     .survey-title-cn {
//                         font-size: 10px;
//                         color: #0056b0;
//                         margin-right: 15px;
//                     }

//                     .survey-title-sn {
//                         height: 20px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         background-color: #0056b0;
//                     }
//                 }
//             }
//             /*关于学会tap end*/

//             /*内容区域 start*/
//             .survey-cont {
//                 width: 100%;

//                 .survey-content {
//                     display: flex;
//                     width: 100%;
//                     // height: 300px;
//                     // border: 1px solid red;

//                     // 内容区域左侧1(技术难题)
//                     .survey-content-left {
//                         display: flex;
//                         flex-direction: column;
//                         width: 68%;
//                         height: 100%;
//                         // border: 1px solid blue;

//                         // 左侧顶部
//                         .detail-top {
//                             font-size: 9px;
//                             padding: 15px 0;
//                             box-sizing: border-box;
//                             border-bottom: 1px solid #e6e6e6;

//                             //左侧顶部---上
//                             .detail-title-top {
//                                 display: flex;
//                                 justify-content: space-between;
//                                 // margin-bottom: 5px;

//                                 > div:nth-child(1) {
//                                     width: 30%;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;

//                                     div {
//                                         margin-bottom: 7px;
//                                     }
//                                 }

//                                 > div:nth-child(2) {
//                                     width: 30%;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                     margin-left: auto;

//                                     div {
//                                         margin-bottom: 7px;
//                                     }
//                                 }
//                                 > div:nth-child(3) {
//                                     width: 30%;
//                                     div {
//                                         margin-bottom: 7px;
//                                     }
//                                 }
//                             }
//                         }

//                         // 左侧底部
//                         .detail-bottom {
//                             margin-top: 10px;
//                             height: 460px;
//                             overflow-y: auto;

//                             &::-webkit-scrollbar {
//                                 width: 6px;
//                                 height: 6px;
//                             }
//                             // 滚动条样式 start
//                             // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
//                             &::-webkit-scrollbar-button {
//                                 display: none;
//                             }
//                             // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
//                             &::-webkit-scrollbar-thumb {
//                                 background: rgba(144, 147, 153, 0.3);
//                                 cursor: pointer;
//                                 border-radius: 4px;
//                             }
//                             // 边角，即两个滚动条的交汇处
//                             &::-webkit-scrollbar-corner {
//                                 display: none;
//                             }
//                             // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
//                             &::-webkit-resizer {
//                                 display: none;
//                             }
//                             // 滚动条样式 end

//                             > div:nth-child(1) {
//                                 text-align: center;
//                                 font-size: 12px;
//                                 margin-bottom: 10px;
//                             }

//                             > h7 {
//                                 display: block;
//                                 text-align: center;
//                                 font-size: 20px;
//                             }
//                             > div {
//                                 font-size: 10px;
//                             }
//                         }
//                     }

//                     // 内容区域右侧
//                     .survey-content-right {
//                         margin-left: auto;
//                         width: 30%;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             /*内容区域 end*/
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
