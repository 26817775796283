<template>
    <!-- 助企名家--详情 -->
    <div class="minkaveDetails">
        <Header></Header>
        <Nav></Nav>
        <div class="minkaveDetails-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- <div class="index-headImg">
                <div class="index-headImg-left">
                    <img src="../../../assets/pc/Index/headL.png" alt="" />
                </div>
                <div class="index-headImg-right">
                    <img src="../../../assets/pc/Index/headR.png" alt="" />
                </div>
            </div> -->

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">助企名家</div>
                    <div class="survey-title-sn">About the Institute</div>
                </div>
                <ul>
                    <li
                        :key="index"
                        v-for="(item, index) in machiningTap"
                        @click="machiningTapEv(item, index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li>
                </ul>
            </div>

            <!-- <div>河南省食品科学技术学会>科学赋能>助企名家>详情</div> -->
            
            <!-- 内容区域 -->
            <div class="minkaveDetails-cont">
                <!-- 专家介绍 -->
                <div
                        class="minkaveDetails-cont-introduce1"
                        v-show="currentIndex == 0"
                    >
                        <div class="text-top">
                            <!-- <div style="color: #000; font-size: 30px;">{{ memberdetailDe.name }}-{{ memberdetailDe.identityname }}</div> -->
                            <div class="text-lefr">
                                <div class="minkaveDetails-introduce1-img">
                                    <img :src="memberdetailDe.image" alt="" />
                                </div>
                                <div v-html="memberdetailDe.content"></div>
                            </div>
                        </div>
                        <!-- <button class="issue" @click="goCompile">去发布</button> -->
                        <div class="minkaveDetails-introduce3-right">
                            <Right></Right>
                        </div>
                    </div>
                <!-- 论文论著 -->
                <div
                    class="minkaveDetails-cont-introduce2"
                    v-show="currentIndex == 1"
                >
                    <ul v-if="worklistDe.length!=0">
                        <li
                            v-for="(item, index) in worklistDe"
                            :key="index"
                            @click="goWorkdetail(item)"
                        >
                            <div class="minkaveDetails-introduce2-img">
                                <img :src="item.image" alt="" />
                            </div>
                            <div class="minkaveDetails-introduce2-right">
                                <div>{{ item.title }}</div>
                                <div>发布时间：{{ item.createtitme }}</div>
                                <div>{{ item.description }}</div>
                            </div>
                        </li>
                        
                        <!-- 左侧内容区域分页 -->
                        <div class="pageDown">
                            <Pagination
                                :pageNo="worklistParams.page"
                                :pageSize="worklistParams.pageSize"
                                :total="worklistTotal"
                                :continues="5"
                                @getPageNo="getPageworkEv"
                            />
                        </div>
                        
                    </ul>
                    
                    <div class="lack" v-else>
                        <img src="../../../assets/pc/Index/lack.png"></img>
                    </div>
                    
                    <!-- <button
                            class="issue"
                            v-if="specialist_id == companyId||companyType==null"
                            @click="goCompile"
                        >
                            去发布
                    </button> -->
                </div>

                <!-- 成果课题 -->
                <div
                    class="minkaveDetails-cont-introduce3"
                    v-show="currentIndex == 2"
                >
                    <div class="minkaveDetails-introduce3-left">
                        <ul v-if="outcomeDe.length!=0" style="width:100%">
                            <li
                                v-for="(itemb, indexb) in outcomeDe"
                                :key="indexb"
                                @click="resultDetil(itemb.id)"
                                style="width:100%"
                            >
                                <div class="survey-left2-title1">
                                    {{ itemb.title }}
                                </div>
                                <div class="styflex">
                                    <div>
                                        <div class="survey-left2-title2">
                                        归属单位：{{ itemb.company }}
                                        </div>
                                        <div class="survey-left2-title2">
                                            评价单位：{{ itemb.authenticate }}
                                        </div>
                                        <div class="survey-left2-title2">
                                            转让方式：{{ itemb.makeway }}
                                        </div>  
                                    </div>
                                    <div>
                                        <div class="survey-left2-title2">
                                            联系电话：{{ itemb.stage }}
                                        </div>
                                        <div class="survey-left2-title2">
                                            完成作者：{{ itemb.author }}
                                        </div>
                                        <div class="survey-left2-title2">
                                            成果名称：{{ itemb.title }}
                                        </div> 
                                    </div>
                                </div>
                                <div class="survey-left2-footer">
                                    <div>浏览:{{itemb.looknum}}</div>
                                    <div>
                                        <img
                                            src="../../../assets/pc/Index/eye.png"
                                            alt=""
                                        />
                                        <span>查看详情</span>
                                    </div>
                                    <!-- <div
                                        @click="gofb"
                                    >
                                        <img
                                            src="../../../assets/pc/Index/bj.png"
                                            alt=""
                                        />
                                        <span>发布推广</span>
                                    </div> -->
                                    <!-- <el-button type="text" @click="open"></el-button> -->
                                </div>
                            </li>

                            <!-- 左侧内容区域分页 -->
                            <div class="pageDown">
                                <Pagination
                                    :pageNo="outcomeParams.page"
                                    :pageSize="outcomeParams.pageSize"
                                    :total="outcomeTotal"
                                    :continues="5"
                                    @getPageNo="getPageoutcomeEv"
                                />
                            </div>
                        </ul>
                        <div class="lack" v-else>
                            <img src="../../../assets/pc/Index/lack.png"></img>
                        </div>
                        <!-- <button
                            class="issue"
                            v-if="specialist_id == companyId||companyType==null"
                            @click="goCompile"
                        >
                            去发布
                        </button> -->
                    </div>
                    <div class="minkaveDetails-introduce3-right">
                        <Right></Right>
                    </div>
                </div>

                <!-- 助企交流 -->
                <div
                    class="minkaveDetails-cont-introduce2"
                    v-show="currentIndex == 3"
                >
                    <ul v-if="newlistDe.length!=0">
                        <li
                            v-for="(item, index) in newlistDe"
                            @click="goNewlistDetail(item)"
                            :key="index"
                        >
                            <div class="minkaveDetails-introduce2-img">
                                <img :src="item.image" alt="" />
                            </div>
                            <div class="minkaveDetails-introduce2-right">
                                <div>{{ item.title }}</div>
                                <div>发布时间：{{ item.createtime }}</div>
                                <div>{{ item.description }}</div>
                            </div>
                        </li>

                        <!-- 左侧内容区域分页 -->
                        <div class="pageDown">
                            <Pagination
                                :pageNo=" newlistParams.page"
                                :pageSize="newlistParams.pageSize"
                                :total="newlistTotal"
                                :continues="5"
                                @getPageNo="getPageoutnewlistEv"
                            />
                        </div>
                        
                    </ul>
                    <div class="lack" v-else>
                        <img src="../../../assets/pc/Index/lack.png"></img>
                    </div>
                    <!-- <button
                            class="issue"
                            v-if="specialist_id == companyId||companyType==null"
                            @click="goCompile"
                        >
                            去发布
                        </button> -->
                </div>

                <!-- 授课视频 -->
                <div
                    class="minkaveDetails-cont-introduce4"
                    v-show="currentIndex == 4"
                >
                    <div class="minkaveDetails-introduce3-left">
                        <ul v-if="videoData.length!=0">
                            <li v-for="(item, index) in videoData" :key="index">
                                <video
                                    ref="movie"
                                    class="video"
                                    :src="item.video"
                                    width="100%"
                                    height="86%"
                                ></video>
                                <i
                                    v-show="item.isplay"
                                    class="el-icon-video-play"
                                    @click="handlePlay($event, item)"
                                ></i>
                                <i
                                    v-show="!item.isplay"
                                    class="el-icon-video-pause"
                                    @click="handlePause($event, item)"
                                ></i>
                                <div>{{ item.title }}</div>
                            </li>
                            <!-- 左侧内容区域分页 -->
                            <div class="pageDown">
                                <Pagination
                                    :pageNo="courseParams.page"
                                    :pageSize="courseParams.pageSize"
                                    :total="courseTotal"
                                    :continues="5"
                                    @getPageNo="getPageoutcourseEv"
                                />
                            </div>
                        </ul>
                        <div class="lack"v-else>
                            <img src="../../../assets/pc/Index/lack.png"></img>
                        </div>
                        <!-- <button
                            class="issue"
                            v-if="specialist_id == companyId ||companyType==null"
                            @click="goCompile"
                        >
                            去发布
                        </button> -->
                        
                    </div>
                    <div class="minkaveDetails-introduce3-right">
                        <Right></Right>
                    </div>
                </div>

                <!-- 难题解答 -->
                <div
                    class="minkaveDetails-cont-introduce5"
                    v-show="currentIndex == 5"
                >
                    <!-- 左侧 -->
                    <div class="minkaveDetails-introduce5-left">
                        <!-- 技术难题 -->
                        <div class="minkaveDetails-introduce5-title">
                            <div>
                                <div></div>
                                <span>相关技术难题</span>
                            </div>
                            <ul>
                                <li
                                    v-for="(itemb, indexb) in puzzleList"
                                    :key="indexb"
                                    @click="puzzleEv(itemb,indexb)"
                                    :class="
                                        currentPuzzle == indexb
                                            ? 'puzzleActive'
                                            : ''
                                    "
                                >
                                    {{ itemb.name }}
                                </li>
                            </ul>
                        </div>
                        <!-- 相关技术难题推荐 -->
                        <div class="minkaveDetails-introduce5-title2">
                            <div class="">其他技术难题</div>
                            <ul>
                                <li
                                    v-for="(itemc, indexc) in puzzlebox"
                                    :key="indexc"
                                >
                                    <div>{{ itemc.title }}</div>
                                    <div>状态:{{ itemc.statustext }}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="minkaveDetails-introduce5-right">
                        <ul>
                            <li
                                v-for="(itemd, indexd) in puzzleDate"
                                :key="indexd"
                            >
                                <div class="minkaveDetails-introduce5-top">
                                    <div>
                                        <div>{{ itemd.title }}</div>
                                        <div>状态：{{ itemd.statustext }}</div>
                                        <div>标签：{{ itemd.lable }}</div>
                                        <div v-html="itemd.description"></div>
                                        <!-- <div>
                                            对标开发，参考某品牌肉干产品，开发一款新型常温长保即食鸡肉干产品。
                                        </div>
                                        <div>项目简介</div>
                                        <div>
                                            对标开发，参考某品牌肉干产品，开发一款新型常温长保即食鸡肉干产品。
                                        </div>
                                        <div>项目简介</div>
                                        <div>
                                            对标开发，参考某品牌肉干产品，开发一款新型常温长保即食鸡肉干产品。
                                        </div> -->
                                    </div>
                                    <div @click="isUnfold(itemd, indexd)">
                                        查看解答
                                    </div>
                                </div>
                                <div
                                    class="minkaveDetails-introduce5-bottom"
                                    v-show="itemd.current"
                                >
                                    <div></div>
                                    <div v-if="itemd.problemlog == null"></div>
                                    <div v-else>{{ itemd.problemlog.description}}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue";
import Nav from "@/components/Nav.vue"; //导航
import Pagination from "@/components/Pagination";
import Advertising from "@/components/Advertising.vue"; //广告图
import {
    detailnavlist,
    memberdetail,
    worklist,
    outcomelist,
    newlist,
    courselist,
    problemlist,
    needCatelist,
    uselist,
} from "../../../network/data";
export default {
    // 组件名称
    name: "minkaveDetails",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 判断是否显示发布按钮
            companyType: 0,
            // 当前tap索引
            currentIndex: 5,
            // 企业会员登录返回的企业id
            companyId: "",
            // 当前助企名家id
            specialist_id: "",
            // 导航栏id
            navId: "1",
            // 关于学会tap数据
            machiningTap: [],
            // 助企名家专业介绍数据
            memberdetailDe: {},
            // 助企名家论文论著
            worklistDe: [],
            // 助企名家成果课题
            outcomeDe: [],
            // 助企名家助企交流
            newlistDe: [],
            // 技术难题左侧tap索引
            currentPuzzle: 0,
            // 技术难题列表
            puzzleList: [],
            // 技术难题推荐列表
            puzzlebox: [],
            // 技术难题右侧列表
            puzzlePlist: [],
            // 技术难题数据
            puzzleDate: [
                // {
                //     current: false,
                //     text: "对标开发，参考某品牌肉干产品，开发一款新型常温长保即食鸡肉干产品。对标开发，参考某品牌肉干产品，开发一款新型常温常 即食鸡肉干产品。配方工艺符合标准法规要求； 口感风味参考某品牌肉干，需通过客户测试。详情请联系食品邦客服食品小邦。",
                // },
                // {
                //     current: false,
                //     text: "对标开发，参考某品牌肉干产品，开发一款新型常温长保即食鸡肉干产品。对标开发，参考某品牌肉干产品，开发一款新型常温常 即食鸡肉干产品。配方工艺符合标准法规要求； 口感风味参考某品牌肉干，需通过客户测试。详情请联系食品邦客服食品小邦。",
                // },
                // {
                //     current: false,
                //     text: "对标开发，参考某品牌肉干产品，开发一款新型常温长保即食鸡肉干产品。对标开发，参考某品牌肉干产品，开发一款新型常温常 即食鸡肉干产品。配方工艺符合标准法规要求； 口感风味参考某品牌肉干，需通过客户测试。详情请联系食品邦客服食品小邦。",
                // },
                // {
                //     current: false,
                //     text: "对标开发，参考某品牌肉干产品，开发一款新型常温长保即食鸡肉干产品。对标开发，参考某品牌肉干产品，开发一款新型常温常 即食鸡肉干产品。配方工艺符合标准法规要求； 口感风味参考某品牌肉干，需通过客户测试。详情请联系食品邦客服食品小邦。",
                // },
            ],
            // 授课视频数据
            videoData: [
                // {
                //     isplay: true,
                //     video: "http://47.92.152.75/123.mp4",
                //     text: "【视频】关于食品按群关于食品安全报告的食品安全",
                // },
                // {
                //     isplay: true,
                //     video: "http://47.92.152.75/123.mp4",
                //     text: "【视频】关于食品按群关于食品安全报告的食品安全",
                // },
            ],

            // 论文论著
            worklistParams: {
                page: 1,
                pageSize: 6,
            },
            worklistTotal: 10,

            // 成果课题
            outcomeParams: {
                page: 1,
                pageSize: 6,
            },
            outcomeTotal: 10,

            // 助企交流
            newlistParams: {
                page: 1,
                pageSize: 6,
            },
            newlistTotal: 10,

            // 授课视频
            courseParams: {
                page: 1,
                pageSize: 6,
            },
            courseTotal: 10,

            // 成果转化分页
            totalPage2: 100,
            pagePrev2: 3,
            pageNext2: 9,

            // 助企交流分页
            totalPage3: 100,
            pagePrev3: 3,
            pageNext3: 9,
            cateid: 1,
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query) {
            this.currentIndex = this.$route.query.index;
            this.companyId = sessionStorage.getItem("companyId");
            this.specialist_id = sessionStorage.getItem("specialist_id");
        }

        this.companyType = sessionStorage.getItem("companyType");

        // 获取助企名家详情页导航栏数据
        this.detailnavlistRe();

        // 获取助企名家-专业介绍数据
        this.memberdetailRe();

        // 获取技术难题分类
        this.needCatelistRe();
        // this.problemlistRe()
    },
    methods: {
        // 获取技术难题分类
        // needCatelistRe() {
        //     needCatelist().then((res) => {
        //         if (res.code == 1) {
        //             this.puzzleList = res.data;
        //         } else {
        //             this.message(res.msg);
        //         }
        //     });
        // },
        needCatelistRe() {
            uselist().then((res) => {
                if (res.code == 1) {
                    this.puzzleList = res.data;
                    if(this.puzzleList.length>0){
                        this.cateid = this.puzzleList[0].id;
                    }
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取助企名家详情页导航栏数据
        detailnavlistRe() {
            detailnavlist().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    if (
                        !sessionStorage.getItem("token") ||
                        this.companyType == 2
                    ) {
                        this.machiningTap = res.data;
                        this.machiningTap.pop();
                    } else {
                        this.machiningTap = res.data;
                    }
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取助企名家-专业介绍数据
        memberdetailRe() {
            let data = {
                menuid: this.navId,
                id: sessionStorage.getItem("specialist_id"),
            };
            memberdetail(data).then((res) => {
                if (res.code == 1) {
                    this.memberdetailDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取助企名家-论文论著列表
        worklistRe() {
            let data = {
                menuid: this.navId,
                id: sessionStorage.getItem("specialist_id"),
                page: this.worklistParams.page,
                pagesize: this.worklistParams.pageSize,
            };
            worklist(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data,'论文论著列表页面');
                    this.worklistDe = res.data.data;
                    this.worklistParams.page = res.data.current_page;
                    this.worklistParams.pageSize = res.data.per_page;
                    this.worklistTotal = res.data.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 助企名家-成果课题列表
        outcomelistRe() {
            let data = {
                menuid: this.navId,
                id: sessionStorage.getItem("specialist_id"),
                page: this.outcomeParams.page,
                pagesize: this.outcomeParams.pageSize,
            };
            outcomelist(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data.data,'成果课题');
                    this.outcomeDe = res.data.data;
                    console.log(this.outcomeDe, "9999999999999");
                    this.outcomeParams.page = res.data.current_page;
                    this.outcomeParams.pageSize = res.data.per_page;
                    this.outcomeTotal = res.data.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 助企名家-助企交流
        newlistRe() {
            let data = {
                menuid: this.navId,
                id: sessionStorage.getItem("specialist_id"),
                page: this.newlistParams.page,
                pagesize: this.newlistParams.pageSize,
            };
            newlist(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.newlistDe = res.data.data;
                    this.newlistParams.page = res.data.current_page;
                    this.newlistParams.pageSize = res.data.per_page;
                    this.newlistTotal = res.data.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取授课视频列表数据
        courselistRe() {
            let data = {
                menuid: this.navId,
                id: sessionStorage.getItem("specialist_id"),
                page: this.courseParams.page,
                pagesize: this.courseParams.pageSize,
            };
            courselist(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    let data = res.data.list.data;
                    this.courseParams.page = res.data.list.current_page;
                    this.courseParams.pageSize = res.data.list.per_page;
                    this.courseTotal = res.data.list.total;
                    data.forEach((v) => {
                        v.isplay = true;
                    });
                    this.videoData = data;
                    // console.log(data);
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取疑难解答数据
        problemlistRe() {
            // console.log('我被点击了')
            let data = {
                id: sessionStorage.getItem("specialist_id"),
                cateid: this.cateid,
            };
            problemlist(data).then((res) => {
                if (res.code == 1) {
                    console.log(res.data, "助企名家疑难解答");
                    this.puzzlebox = res.data.list;
                    let data = res.data.plist;
                    data.forEach((v) => {
                        v.current = false;
                    });
                    this.puzzleDate = data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        machiningTapEv(item, index) {
            // this.surveyData = [];
            this.currentIndex = index;
            this.navId = item.id;
            if (this.currentIndex == 1) {
                this.worklistParams.page = 1;
                this.worklistParams.pageSize = 10;
                this.worklistRe();
            } else if (this.currentIndex == 2) {
                this.outcomelistRe();
            } else if (this.currentIndex == 3) {
                this.newlistRe();
            } else if (this.currentIndex == 4) {
                this.courselistRe();
            } else if (this.currentIndex == 5) {
                this.problemlistRe();
            }
        },

        // 论文论著分页点击事件
        getPageworkEv(e) {
            this.worklistParams.page = e;
            this.worklistRe();
        },

        // 成果课题分页点击事件
        getPageoutcomeEv(e) {
            this.outcomeParams.page = e;
        },

        // 助企交流分页点击事件
        getPageoutnewlistEv(e) {
            this.newlistParams.page = e;
            this.newlistRe();
        },

        // 授课视频分页点击事件
        getPageoutcourseEv(e) {
            this.courseParams.page = e;
            this.courselistRe();
        },

        // 技术难题左侧tap点击事件
        puzzleEv(itemb, index) {
            this.currentPuzzle = index;
            this.cateid = itemb.id;
            this.problemlistRe();
        },
        // 技术难题展开事件
        isUnfold(itemd, indexd) {
            itemd.current = !itemd.current;
        },
        // 是否播放视频
        handlePlay(e, item) {
            // console.log(item.isplay);
            e.target.parentElement.firstElementChild.play();
            item.isplay = false;

            // console.log(item.isplay);
        },
        // 是否播放视频
        handlePause(e, item) {
            e.target.parentElement.firstElementChild.pause();
            item.isplay = true;
        },
        // 跳转到论文论著详情页
        goWorkdetail(item) {
            this.$router.push({
                path: "/science/Workdetail",
                query: {
                    id: item.id,
                    type: 1,
                },
            });
        },
        // 跳转到成果转化详情页
        resultDetil(id) {
            // this.$alert('请注册会员！', {
            //     confirmButtonText: '确定',
            //     });
            this.$router.push({
                path: `/science/demandResultDetail`,
                query: {
                    id: id,
                    type: 1,
                },
            });
        },
        gofb() {
            this.$alert("请注册会员！", {
                confirmButtonText: "确定",
            });
            // this.$router.push({
            //     path: "/science/demandResultIssue/",
            //     query: {
            //         indexa: 0,
            //     },
            // });
        },
        // 跳转到助企交流详情页
        goNewlistDetail(item) {
            this.$router.push({
                path: "/science/Workdetail",
                query: {
                    id: item.id,
                    type: 2,
                },
            });
        },
        // 跳转到去发布编辑页面
        goCompile() {
            // 判断是否登录
            if (!sessionStorage.getItem("token")) {
                this.$router.push({
                    path: "/login",
                });
            } else {
                if (this.currentIndex == 0) {
                    this.$router.push({
                        path: "/science/compile",
                        query: {
                            type: 0,
                            // item: JSON.stringify(this.memberdetailDe),
                        },
                    });
                    window.scrollTo(0, 0);
                } else if (this.currentIndex == 1) {
                    this.$router.push({
                        path: "/science/compile",
                        query: {
                            type: 1,
                            // item: JSON.stringify(this.worklistDe),
                        },
                    });
                    window.scrollTo(0, 0);
                } else if (this.currentIndex == 2) {
                    // this.$router.push({
                    //     path: "/science/compile",
                    //     query: {
                    //         type: 2,
                    //         item: JSON.stringify(this.outcomeDe.data),
                    //     },
                    // });
                    this.$router.push({
                        path: "/science/demandResultIssue/",
                        query: {
                            indexa: 0,
                        },
                    });
                    window.scrollTo(0, 0);
                } else if (this.currentIndex == 3) {
                    this.$router.push({
                        path: "/science/compile",
                        query: {
                            type: 3,
                            // item: JSON.stringify(this.newlistDe),
                        },
                    });
                    window.scrollTo(0, 0);
                } else if (this.currentIndex == 4) {
                    this.$router.push({
                        path: "/science/compile",
                        query: {
                            type: 4,
                            // item: JSON.stringify(this.newlistDe),
                        },
                    });
                    window.scrollTo(0, 0);
                }
            }
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Right,
        Nav,
        Pagination,
        Advertising,
    },
};
</script>

<style lang="scss">
// .minkaveDetails {
//     width: 100%;
//     // height: 1000px;
//     .minkaveDetails-wrap {
//         width: 70%;
//         height: 100%;
//         margin: 0 auto;
//         margin-top: 15px;
//         margin-bottom: 40px;
//         // border: 1px solid red;

//         /*顶部图片start*/
//         .index-headImg {
//             position: relative;
//             z-index: 0;
//             width: 100%;
//             height: 50px;
//             margin-top: 15px;
//             display: flex;
//             justify-content: space-between;

//             // 左侧图片
//             .index-headImg-left {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }

//             // 右侧图片
//             .index-headImg-right {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }
//         }
//         /*顶部图片end*/

//         /*关于学会tap start*/
//         .survey-nav {
//             display: flex;
//             align-items: center;
//             width: 100%;
//             padding: 10px 0;
//             box-sizing: border-box;
//             border-bottom: 0.5px solid #e4e4e4;

//             .survey-nav-title {
//                 width: 28%;
//                 display: flex;
//                 align-items: center;
//                 // border: 1px solid red;

//                 .survey-title-cn {
//                     font-size: 10px;
//                     color: #0056b0;
//                     margin-right: 15px;
//                 }

//                 .survey-title-sn {
//                     line-height: 10px;
//                     height: 10px;
//                     font-size: 6px;
//                     color: #fff;
//                     padding: 0 6px;
//                     background-color: #0056b0;
//                     white-space: nowrap;
//                     overflow: hidden;
//                     text-overflow: ellipsis;
//                 }
//             }

//             > ul {
//                 width: 80%;
//                 list-style: none;
//                 display: flex;
//                 // border: 1px solid red;

//                 .active {
//                     color: #0068d5;
//                 }

//                 li {
//                     cursor: pointer;
//                     margin-left: 10px;
//                     padding-left: 10px;
//                     font-size: 10px;
//                     border-left: 1px solid #e9e9e9;
//                 }
//             }
//         }
//         /*关于学会tap end*/

//         /*面包屑 start*/
//         > div:nth-child(3) {
//             font-size: 8px;
//             margin-top: 10px;
//             color: #989898;
//         }
//         /*面包屑 end*/
//         .zhiti {
//             font-size: 50px;
//             color: #000000;
//             font-weight: 600;
//         }
//         /*详情页内容区域 start*/
//         .minkaveDetails-cont {
//             width: 100%;
//             margin-top: 10px;
//             // height: 80%;
//             // border: 1px solid red;

//             //专家介绍
//             // .floating{
//             //     display: flex;
//             // }
//             .minkaveDetails-cont-introduce1 {
//                 // text-align: center;
//                 display: flex;
//                 // border: 0.5px solid #f7f7f7;
//                 .text-top {
//                     width: 68%;
//                     height: 86%;
//                     padding: 10px;
//                     box-sizing: border-box;
//                 }
//                 // .text-lefr{

//                 // }
//                 .issue {
//                     cursor: pointer;
//                     width: 60px;
//                     height: 20px;
//                     font-size: 12px;
//                     background-color: #0056b0;
//                     border: none;
//                     border-radius: 10px;
//                     color: #fff;
//                 }

//                 .minkaveDetails-introduce1-img {
//                     width: 100px;
//                     height: 130px;
//                     margin: 0 auto;

//                     > img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 > div:nth-child(2) {
//                     margin-top: 10px;
//                     font-size: 20px;
//                 }

//                 > div:nth-child(3) {
//                     margin-top: 2px;
//                     font-size: 13px;
//                 }

//                 > div:nth-child(4) {
//                     width: 100%;
//                     // height: 100%;
//                     font-size: 10px;
//                     margin-top: 12px;
//                     // border: 1px solid blue;
//                 }
//             }
//             .minkaveDetails-introduce3-right {
//                 width: 32%;
//             }

//             //论文论著、助企交流
//             .minkaveDetails-cont-introduce2 {
//                 width: 100%;
//                 > ul {
//                     list-style: none;

//                     li {
//                         cursor: pointer;
//                         display: flex;
//                         width: 100%;
//                         height: 110px;
//                         padding: 10px;
//                         margin-bottom: 20px;
//                         box-sizing: border-box;
//                         // border: 1px solid red;
//                         box-shadow: 2px 2px 10px #e1e0e0;

//                         .minkaveDetails-introduce2-img {
//                             width: 20%;
//                             height: 100%;

//                             > img {
//                                 width: 100%;
//                                 height: 100%;
//                             }
//                         }

//                         .minkaveDetails-introduce2-right {
//                             margin-left: auto;
//                             width: 78%;
//                             > div:nth-child(1) {
//                                 font-size: 15px;
//                             }

//                             > div:nth-child(2) {
//                                 margin-top: 5px;
//                                 font-size: 10px;
//                                 color: #b3b3b3;
//                             }

//                             > div:nth-child(3) {
//                                 margin-top: 15px;
//                                 font-size: 10px;
//                                 color: #808080;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                                 display: -webkit-box;
//                                 -webkit-line-clamp: 2; //行数
//                                 -webkit-box-orient: vertical;
//                             }
//                         }
//                     }

//                     .pageDown {
//                         width: 100%;
//                         margin: 20px auto;
//                         text-align: start;
//                         // border: 1px solid blue;
//                     }
//                 }

//                 .lack {
//                     margin: 0 auto;
//                     width: 260px;
//                     height: 300px;
//                     > img {
//                         width: 100%;
//                     }
//                 }

//                 .issue {
//                     cursor: pointer;
//                     width: 60px;
//                     height: 20px;
//                     font-size: 12px;
//                     background-color: #0056b0;
//                     border: none;
//                     border-radius: 10px;
//                     color: #fff;
//                 }
//             }

//             // 成果课题
//             .minkaveDetails-cont-introduce3 {
//                 display: flex;
//                 width: 100%;
//                 // border: 1px solid red;

//                 .minkaveDetails-introduce3-left {
//                     width: 68%;

//                     > ul {
//                         width: 100%;
//                         display: flex;
//                         flex-wrap: wrap;
//                         justify-content: space-between;
//                         list-style: none;

//                         li {
//                             width: 48%;
//                             height: 110px;
//                             padding: 5px;
//                             box-sizing: border-box;
//                             margin-bottom: 18px;
//                             border: 1px solid #f7f7f7;

//                             .survey-left2-title1 {
//                                 font-size: 12px;
//                                 white-space: nowrap;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                                 text-align: center;
//                             }
//                             .styflex {
//                                 display: flex;
//                                 justify-content: space-between;
//                             }
//                             .survey-left2-title2 {
//                                 margin-top: 5px;
//                                 // font-size: 9px;
//                                 color: #666666;
//                                 white-space: nowrap;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                             }

//                             .survey-left2-footer {
//                                 display: flex;
//                                 align-content: center;
//                                 margin-top: 16px;
//                                 font-size: 8px;

//                                 > div:nth-child(1) {
//                                     width: 60px;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }
//                                 > div:nth-child(2) {
//                                     cursor: pointer;
//                                     display: flex;
//                                     align-items: center;
//                                     margin-left: auto;
//                                     margin-right: 10px;

//                                     > img {
//                                         width: 13px;
//                                         height: 9px;
//                                         margin-right: 2px;
//                                     }
//                                 }
//                                 > div:nth-child(3) {
//                                     cursor: pointer;
//                                     display: flex;
//                                     align-items: center;

//                                     > img {
//                                         width: 10px;
//                                         height: 9px;
//                                         margin-right: 2px;
//                                     }
//                                 }
//                             }
//                         }

//                         // 成果转化分页
//                         .pageDown {
//                             width: 100%;
//                             margin: 20px auto;
//                             text-align: start;
//                             // border: 1px solid blue;
//                         }
//                     }

//                     .lack {
//                         margin: 0 auto;
//                         width: 260px;
//                         height: 300px;
//                         > img {
//                             width: 100%;
//                         }
//                     }

//                     .issue {
//                         cursor: pointer;
//                         width: 60px;
//                         height: 20px;
//                         font-size: 12px;
//                         background-color: #0056b0;
//                         border: none;
//                         border-radius: 10px;
//                         color: #fff;
//                     }
//                 }

//                 .minkaveDetails-introduce3-right {
//                     margin-left: auto;
//                     width: 30%;
//                 }
//             }

//             // 授权视频
//             .minkaveDetails-cont-introduce4 {
//                 display: flex;
//                 width: 100%;
//                 border: 1px solid #f7f7f7;

//                 .minkaveDetails-introduce3-left {
//                     width: 68%;

//                     > ul {
//                         width: 100%;
//                         display: flex;
//                         flex-wrap: wrap;
//                         justify-content: space-between;
//                         list-style: none;

//                         li {
//                             position: relative;
//                             // border: 1px solid red;
//                             width: 48%;
//                             // height: 146px;
//                             margin-bottom: 20px;

//                             video {
//                                 display: block;
//                             }
//                             i {
//                                 // border: 1px solid red;
//                                 position: absolute;
//                                 left: 50%;
//                                 top: 50%;
//                                 transform: translate(-50%, -50%);
//                                 font-size: 30px;
//                                 color: #95bf97;
//                                 cursor: pointer;
//                                 opacity: 0.85;
//                                 &.el-icon-video-pause {
//                                     opacity: 0;
//                                     z-index: 99;
//                                     transition: all 0.4s;
//                                 }
//                             }
//                             > div {
//                                 height: 10%;
//                                 padding: 5px 0;
//                                 font-size: 9px;
//                             }

//                             &:hover {
//                                 .el-icon-video-pause {
//                                     opacity: 0.85;
//                                 }
//                             }
//                         }

//                         // 成果转化分页
//                         .pageDown {
//                             width: 100%;
//                             margin: 20px auto;
//                             text-align: start;
//                             // border: 1px solid blue;
//                         }
//                     }

//                     .lack {
//                         margin: 0 auto;
//                         width: 260px;
//                         height: 300px;
//                         > img {
//                             width: 100%;
//                         }
//                     }

//                     .issue {
//                         cursor: pointer;
//                         width: 60px;
//                         height: 20px;
//                         font-size: 12px;
//                         background-color: #0056b0;
//                         border: none;
//                         border-radius: 10px;
//                         color: #fff;
//                     }
//                 }

//                 .minkaveDetails-introduce3-right {
//                     margin-left: auto;
//                     width: 30%;
//                 }
//             }

//             //难题解答
//             .minkaveDetails-cont-introduce5 {
//                 width: 100%;
//                 // height: 300px;
//                 display: flex;

//                 .minkaveDetails-introduce5-left {
//                     width: 24%;
//                     // height: 216px;
//                     // border: 1px solid red;

//                     // 技术难题
//                     .minkaveDetails-introduce5-title {
//                         > div:nth-child(1) {
//                             display: flex;
//                             align-items: center;
//                             height: 35px;
//                             line-height: 35px;
//                             padding: 10px;
//                             box-sizing: border-box;
//                             // border: 1px solid red;
//                             background-color: #0056b0;

//                             > div:nth-child(1) {
//                                 width: 1.5px;
//                                 height: 16px;
//                                 background: #fff;
//                             }

//                             > span {
//                                 margin-top: -1.5px;
//                                 margin-left: 8px;
//                                 font-size: 12px;
//                                 color: #fff;
//                             }
//                         }

//                         > ul {
//                             list-style: none;

//                             .puzzleActive {
//                                 color: #fff;
//                                 background-color: #8fc320;
//                             }

//                             li {
//                                 text-align: center;
//                                 height: 35px;
//                                 line-height: 35px;
//                                 border-bottom: 1px solid #f2f2f2;
//                                 letter-spacing: 1px;
//                                 font-size: 12px;
//                             }
//                         }
//                     }

//                     // 技术难题推荐
//                     .minkaveDetails-introduce5-title2 {
//                         margin-top: 30px;
//                         > div:nth-child(1) {
//                             font-size: 12px;
//                         }

//                         > ul {
//                             list-style: none;

//                             li {
//                                 margin-top: 10px;
//                                 padding: 5px;
//                                 box-sizing: border-box;
//                                 border-radius: 4px;
//                                 font-size: 12px;
//                                 background-color: #f8f8f8;

//                                 > div:nth-child(1) {
//                                     font-size: 11px;
//                                 }

//                                 > div:nth-child(2) {
//                                     margin-top: 4px;
//                                     font-size: 9px;
//                                     color: #9f9f9f;
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 .minkaveDetails-introduce5-right {
//                     width: 73%;
//                     height: 100%;
//                     margin-left: auto;
//                     // border: 1px solid red;

//                     > ul {
//                         list-style: none;

//                         li {
//                             box-sizing: border-box;
//                             margin-bottom: 20px;

//                             // 上部
//                             .minkaveDetails-introduce5-top {
//                                 display: flex;
//                                 padding: 3px;
//                                 border: 2px solid #ededed;

//                                 > div:nth-child(1) {
//                                     > div:nth-child(1) {
//                                         font-size: 12px;
//                                     }
//                                     > div:nth-child(2) {
//                                         font-size: 9px;
//                                         margin: 3px 0px;
//                                     }
//                                     > div:nth-child(3) {
//                                         font-size: 9px;
//                                         margin: 3px 0px;
//                                     }
//                                     > div:nth-child(4) {
//                                         font-size: 10px;
//                                         width: 360px;
//                                     }
//                                     > div:nth-child(5) {
//                                         font-size: 9px;
//                                         margin: 5px 0px;
//                                     }
//                                     > div:nth-child(6) {
//                                         font-size: 10px;
//                                     }
//                                     > div:nth-child(7) {
//                                         font-size: 9px;
//                                         margin: 5px 0px;
//                                     }
//                                     > div:nth-child(8) {
//                                         font-size: 10px;
//                                     }
//                                     > div:nth-child(9) {
//                                         font-size: 9px;
//                                         margin-top: 5px;
//                                         margin-bottom: 2px;
//                                     }
//                                 }
//                                 > div:nth-child(2) {
//                                     height: 20px;
//                                     // line-height: 20px;
//                                     padding: 3px 16px;
//                                     box-sizing: border-box;
//                                     margin-left: auto;
//                                     font-size: 9px;
//                                     color: #fff;
//                                     border-radius: 2px;
//                                     background-color: #0056b0;
//                                 }
//                             }

//                             // 下部
//                             .minkaveDetails-introduce5-bottom {
//                                 width: 100%;
//                                 color: #989898;
//                                 // border: 1px solid red;

//                                 > div:nth-child(1) {
//                                     width: 0;
//                                     margin-left: 60px;
//                                     border-left: 6px solid transparent;
//                                     border-right: 6px solid transparent;
//                                     border-bottom: 10px solid #f2f2f2;
//                                     // margin: 50px;
//                                 }

//                                 > div:nth-child(2) {
//                                     padding: 5px;
//                                     box-sizing: border-box;
//                                     font-size: 9px;
//                                     border-radius: 5px;
//                                     background-color: #f2f2f2;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }

//         /*详情页内容区域 end*/
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//     .minkaveDetails {
//         width: 100%;
//         // height: 1000px;
//         .minkaveDetails-wrap {
//             width: 70%;
//             height: 100%;
//             margin: 0 auto;
//             margin-top: 15px;
//             margin-bottom: 40px;
//             // border: 1px solid red;

//             /*顶部图片start*/
//             .index-headImg {
//                 position: relative;
//                 z-index: 0;
//                 width: 100%;
//                 height: 50px;
//                 margin-top: 15px;
//                 display: flex;
//                 justify-content: space-between;

//                 // 左侧图片
//                 .index-headImg-left {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧图片
//                 .index-headImg-right {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//             /*顶部图片end*/

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 0.5px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 30%;
//                     display: flex;
//                     align-items: center;
//                     // border: 1px solid red;

//                     .survey-title-cn {
//                         font-size: 10px;
//                         color: #0056b0;
//                         margin-right: 15px;
//                     }

//                     .survey-title-sn {
//                         height: 10px;
//                         line-height: 10px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         background-color: #0056b0;
//                     }
//                 }

//                 > ul {
//                     width: 70%;
//                     list-style: none;
//                     display: flex;
//                     // border: 1px solid red;

//                     .active {
//                         color: #0068d5;
//                     }

//                     li {
//                         cursor: pointer;
//                         text-align: center;
//                         margin-left: 5px;
//                         padding-left: 5px;
//                         font-size: 7.5px;
//                         border-left: 1px solid #e9e9e9;
//                     }
//                 }
//             }
//             /*关于学会tap end*/

//             /*面包屑 start*/
//             > div:nth-child(3) {
//                 font-size: 8px;
//                 margin-top: 10px;
//                 color: #989898;
//             }
//             /*面包屑 end*/

//             /*详情页内容区域 start*/
//             .minkaveDetails-cont {
//                 width: 100%;
//                 margin-top: 20px;
//                 // height: 80%;
//                 // border: 1px solid red;

//                 //专家介绍
//                 .minkaveDetails-cont-introduce1 {
//                     // text-align: center;
//                     width: 100%;
//                     height: 86%;
//                     padding: 10px;
//                     box-sizing: border-box;
//                     // border: 0.5px solid #f7f7f7;
//                     // .floating{
//                     //     display: flex;
//                     // }
//                     .minkaveDetails-introduce1-img {
//                         width: 100px;
//                         height: 130px;
//                         margin: 0 auto;

//                         > img {
//                             width: 100%;
//                             height: 100%;
//                         }
//                     }

//                     > div:nth-child(2) {
//                         margin-top: 10px;
//                         font-size: 20px;
//                     }

//                     > div:nth-child(3) {
//                         margin-top: 2px;
//                         font-size: 13px;
//                     }

//                     > div:nth-child(4) {
//                         width: 100%;
//                         // height: 100%;
//                         font-size: 10px;
//                         margin-top: 12px;
//                         // border: 1px solid blue;
//                     }
//                 }

//                 //论文论著、助企交流
//                 .minkaveDetails-cont-introduce2 {
//                     width: 100%;
//                     > ul {
//                         list-style: none;

//                         li {
//                             cursor: pointer;
//                             display: flex;
//                             width: 100%;
//                             height: 90px;
//                             padding: 5px;
//                             margin-bottom: 20px;
//                             box-sizing: border-box;
//                             // border: 1px solid red;
//                             box-shadow: 2px 2px 10px #e1e0e0;

//                             .minkaveDetails-introduce2-img {
//                                 width: 20%;
//                                 height: 100%;

//                                 > img {
//                                     width: 100%;
//                                     height: 100%;
//                                 }
//                             }

//                             .minkaveDetails-introduce2-right {
//                                 margin-left: auto;
//                                 width: 78%;
//                                 > div:nth-child(1) {
//                                     font-size: 15px;
//                                 }

//                                 > div:nth-child(2) {
//                                     margin-top: 5px;
//                                     font-size: 10px;
//                                     color: #b3b3b3;
//                                 }

//                                 > div:nth-child(3) {
//                                     margin-top: 15px;
//                                     font-size: 10px;
//                                     color: #808080;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                     display: -webkit-box;
//                                     -webkit-line-clamp: 2; //行数
//                                     -webkit-box-orient: vertical;
//                                 }
//                             }
//                         }

//                         .pageDown {
//                             width: 100%;
//                             margin: 20px auto;
//                             text-align: start;
//                             // border: 1px solid blue;
//                         }
//                     }
//                 }

//                 // 成果课题
//                 .minkaveDetails-cont-introduce3 {
//                     display: flex;
//                     width: 100%;
//                     // border: 1px solid red;

//                     .minkaveDetails-introduce3-left {
//                         width: 68%;

//                         > ul {
//                             width: 100%;
//                             display: flex;
//                             flex-wrap: wrap;
//                             justify-content: space-between;
//                             list-style: none;

//                             li {
//                                 width: 48%;
//                                 height: 100px;
//                                 padding: 5px;
//                                 box-sizing: border-box;
//                                 margin-bottom: 18px;
//                                 border: 1px solid #f7f7f7;

//                                 .survey-left2-title1 {
//                                     font-size: 12px;
//                                 }

//                                 .survey-left2-title2 {
//                                     margin-top: 3px;
//                                     font-size: 9px;
//                                     color: #666666;
//                                 }

//                                 .survey-left2-footer {
//                                     display: flex;
//                                     align-content: center;
//                                     margin-top: 16px;
//                                     font-size: 8px;

//                                     > div:nth-child(1) {
//                                         display: none;
//                                         width: 60px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }
//                                     > div:nth-child(2) {
//                                         cursor: pointer;
//                                         display: flex;
//                                         align-items: center;
//                                         margin: 0px;
//                                         margin-right: 10px;

//                                         > img {
//                                             width: 13px;
//                                             height: 9px;
//                                             margin-right: 2px;
//                                         }
//                                     }
//                                     > div:nth-child(3) {
//                                         cursor: pointer;
//                                         display: flex;
//                                         margin-left: auto;
//                                         align-items: center;

//                                         > img {
//                                             width: 10px;
//                                             height: 9px;
//                                             margin-right: 2px;
//                                         }
//                                     }
//                                 }
//                             }

//                             // 成果转化分页
//                             .pageDown {
//                                 width: 100%;
//                                 margin: 20px auto;
//                                 text-align: start;
//                                 // border: 1px solid blue;
//                             }
//                         }
//                     }

//                     .minkaveDetails-introduce3-right {
//                         margin-left: auto;
//                         width: 30%;
//                     }
//                 }

//                 // 授权视频
//                 .minkaveDetails-cont-introduce4 {
//                     display: flex;
//                     width: 100%;
//                     border: 1px solid #f7f7f7;

//                     .minkaveDetails-introduce3-left {
//                         width: 68%;

//                         > ul {
//                             width: 100%;
//                             display: flex;
//                             flex-wrap: wrap;
//                             justify-content: space-between;
//                             list-style: none;

//                             li {
//                                 position: relative;
//                                 // border: 1px solid red;
//                                 width: 48%;
//                                 // height: 146px;
//                                 margin-bottom: 20px;

//                                 video {
//                                     display: block;
//                                 }
//                                 i {
//                                     // border: 1px solid red;
//                                     position: absolute;
//                                     left: 50%;
//                                     top: 50%;
//                                     transform: translate(-50%, -50%);
//                                     font-size: 30px;
//                                     color: #95bf97;
//                                     cursor: pointer;
//                                     opacity: 0.85;
//                                     &.el-icon-video-pause {
//                                         opacity: 0;
//                                         z-index: 99;
//                                         transition: all 0.4s;
//                                     }
//                                 }
//                                 > div {
//                                     height: 10%;
//                                     padding: 5px 0;
//                                     font-size: 9px;
//                                 }

//                                 &:hover {
//                                     .el-icon-video-pause {
//                                         opacity: 0.85;
//                                     }
//                                 }
//                             }

//                             // 成果转化分页
//                             .pageDown {
//                                 width: 100%;
//                                 margin: 20px auto;
//                                 text-align: start;
//                                 // border: 1px solid blue;
//                             }
//                         }
//                     }

//                     .minkaveDetails-introduce3-right {
//                         margin-left: auto;
//                         width: 30%;
//                     }
//                 }

//                 //难题解答
//                 .minkaveDetails-cont-introduce5 {
//                     width: 100%;
//                     // height: 300px;
//                     display: flex;

//                     .minkaveDetails-introduce5-left {
//                         width: 24%;
//                         // height: 216px;
//                         // border: 1px solid red;

//                         // 技术难题
//                         .minkaveDetails-introduce5-title {
//                             > div:nth-child(1) {
//                                 display: flex;
//                                 align-items: center;
//                                 height: 35px;
//                                 line-height: 35px;
//                                 padding: 10px;
//                                 box-sizing: border-box;
//                                 // border: 1px solid red;
//                                 background-color: #0056b0;

//                                 > div:nth-child(1) {
//                                     width: 1.5px;
//                                     height: 16px;
//                                     background: #fff;
//                                 }

//                                 > span {
//                                     margin-top: -1.5px;
//                                     margin-left: 8px;
//                                     font-size: 12px;
//                                     color: #fff;
//                                 }
//                             }

//                             > ul {
//                                 list-style: none;

//                                 .puzzleActive {
//                                     color: #fff;
//                                     background-color: #8fc320;
//                                 }

//                                 li {
//                                     text-align: center;
//                                     height: 35px;
//                                     line-height: 35px;
//                                     border-bottom: 1px solid #f2f2f2;
//                                     letter-spacing: 1px;
//                                     font-size: 12px;
//                                 }
//                             }
//                         }

//                         // 技术难题推荐
//                         .minkaveDetails-introduce5-title2 {
//                             margin-top: 30px;
//                             > div:nth-child(1) {
//                                 font-size: 12px;
//                             }

//                             > ul {
//                                 list-style: none;

//                                 li {
//                                     margin-top: 10px;
//                                     padding: 5px;
//                                     box-sizing: border-box;
//                                     border-radius: 4px;
//                                     font-size: 12px;
//                                     background-color: #f8f8f8;

//                                     > div:nth-child(1) {
//                                         font-size: 11px;
//                                     }

//                                     > div:nth-child(2) {
//                                         margin-top: 4px;
//                                         font-size: 9px;
//                                         color: #9f9f9f;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     .minkaveDetails-introduce5-right {
//                         width: 73%;
//                         height: 100%;
//                         margin-left: auto;
//                         // border: 1px solid red;

//                         > ul {
//                             list-style: none;

//                             li {
//                                 box-sizing: border-box;
//                                 margin-bottom: 20px;

//                                 // 上部
//                                 .minkaveDetails-introduce5-top {
//                                     display: flex;
//                                     padding: 3px;
//                                     border: 2px solid #ededed;

//                                     > div:nth-child(1) {
//                                         > div:nth-child(1) {
//                                             font-size: 12px;
//                                         }
//                                         > div:nth-child(2) {
//                                             font-size: 9px;
//                                             margin: 3px 0px;
//                                         }
//                                         > div:nth-child(3) {
//                                             font-size: 9px;
//                                             margin: 3px 0px;
//                                         }
//                                         > div:nth-child(4) {
//                                             font-size: 10px;
//                                         }
//                                         > div:nth-child(5) {
//                                             font-size: 9px;
//                                             margin: 5px 0px;
//                                         }
//                                         > div:nth-child(6) {
//                                             font-size: 10px;
//                                         }
//                                         > div:nth-child(7) {
//                                             font-size: 9px;
//                                             margin: 5px 0px;
//                                         }
//                                         > div:nth-child(8) {
//                                             font-size: 10px;
//                                         }
//                                         > div:nth-child(9) {
//                                             font-size: 9px;
//                                             margin-top: 5px;
//                                             margin-bottom: 2px;
//                                         }
//                                     }
//                                     > div:nth-child(2) {
//                                         height: 20px;
//                                         // line-height: 20px;
//                                         padding: 3px 8px;
//                                         box-sizing: border-box;
//                                         margin-left: auto;
//                                         font-size: 9px;
//                                         color: #fff;
//                                         border-radius: 2px;
//                                         background-color: #0056b0;
//                                     }
//                                 }

//                                 // 下部
//                                 .minkaveDetails-introduce5-bottom {
//                                     width: 100%;
//                                     color: #989898;
//                                     // border: 1px solid red;

//                                     > div:nth-child(1) {
//                                         width: 0;
//                                         margin-left: 60px;
//                                         border-left: 6px solid transparent;
//                                         border-right: 6px solid transparent;
//                                         border-bottom: 10px solid #f2f2f2;
//                                         // margin: 50px;
//                                     }

//                                     > div:nth-child(2) {
//                                         padding: 5px;
//                                         box-sizing: border-box;
//                                         font-size: 9px;
//                                         border-radius: 5px;
//                                         background-color: #f2f2f2;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }

//             /*详情页内容区域 end*/
//         }
//     }
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .minkaveDetails {
//         width: 100%;
//         // height: 1000px;
//         .minkaveDetails-wrap {
//             width: 80%;
//             height: 100%;
//             margin: 0 auto;
//             margin-top: 15px;
//             margin-bottom: 40px;
//             // border: 1px solid red;

//             /*顶部图片start*/
//             .index-headImg {
//                 height: 30px;
//                 line-height: 30px;
//                 margin-top: 10px;
//             }
//             /*顶部图片end*/

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 1px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 30%;
//                     display: flex;
//                     align-items: center;
//                     // border: 1px solid red;

//                     .survey-title-cn {
//                         font-size: 10px;
//                         color: #0056b0;
//                         margin-right: 0px;
//                     }

//                     .survey-title-sn {
//                         height: 10px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         background-color: #0056b0;
//                         white-space: nowrap;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                     }
//                 }

//                 > ul {
//                     width: 80%;
//                     list-style: none;
//                     display: flex;
//                     // border: 1px solid red;

//                     .active {
//                         color: #0068d5;
//                     }

//                     li {
//                         cursor: pointer;
//                         margin-left: 5px;
//                         padding-left: 5px;
//                         font-size: 6px;
//                         border-left: 1px solid #e9e9e9;
//                     }
//                 }
//             }
//             /*关于学会tap end*/

//             /*面包屑 start*/
//             > div:nth-child(3) {
//                 font-size: 8px;
//                 margin-top: 10px;
//                 color: #989898;
//             }
//             /*面包屑 end*/

//             /*详情页内容区域 start*/
//             .minkaveDetails-cont {
//                 width: 100%;
//                 margin-top: 10px;
//                 // height: 80%;
//                 // border: 1px solid red;

//                 //专家介绍
//                 .minkaveDetails-cont-introduce1 {
//                     // text-align: center;
//                     width: 100%;
//                     height: 86%;
//                     padding: 10px;
//                     box-sizing: border-box;
//                     // border: 1px solid black;
//                     // .floating{
//                     //     display: flex;
//                     // }
//                     .minkaveDetails-introduce1-img {
//                         width: 100px;
//                         height: 130px;
//                         margin: 0 auto;

//                         > img {
//                             width: 100%;
//                             height: 100%;
//                         }
//                     }

//                     > div:nth-child(2) {
//                         margin-top: 10px;
//                         font-size: 20px;
//                     }

//                     > div:nth-child(3) {
//                         margin-top: 2px;
//                         font-size: 13px;
//                     }

//                     > div:nth-child(4) {
//                         width: 100%;
//                         // height: 100%;
//                         font-size: 10px;
//                         margin-top: 12px;
//                         // border: 1px solid blue;
//                     }
//                 }

//                 //论文论著、助企交流
//                 .minkaveDetails-cont-introduce2 {
//                     width: 100%;
//                     height: 600px;
//                     > ul {
//                         list-style: none;

//                         li {
//                             display: flex;
//                             width: 100%;
//                             height: 80px;
//                             padding: 8px;
//                             margin-bottom: 10px;
//                             box-sizing: border-box;
//                             // border: 1px solid red;
//                             box-shadow: 2px 2px 10px #e1e0e0;

//                             .minkaveDetails-introduce2-img {
//                                 width: 20%;
//                                 height: 100%;

//                                 > img {
//                                     width: 100%;
//                                     height: 100%;
//                                 }
//                             }

//                             .minkaveDetails-introduce2-right {
//                                 margin-left: auto;
//                                 width: 78%;
//                                 > div:nth-child(1) {
//                                     font-size: 13px;
//                                 }

//                                 > div:nth-child(2) {
//                                     margin-top: 3px;
//                                     font-size: 8px;
//                                     color: #b3b3b3;
//                                 }

//                                 > div:nth-child(3) {
//                                     margin-top: 5px;
//                                     font-size: 10px;
//                                     color: #808080;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                     display: -webkit-box;
//                                     -webkit-line-clamp: 2; //行数
//                                     -webkit-box-orient: vertical;
//                                 }
//                             }
//                         }

//                         .pageDown {
//                             width: 100%;
//                             margin: 20px auto;
//                             text-align: start;
//                             // border: 1px solid blue;
//                         }
//                     }
//                 }

//                 // 成果课题
//                 .minkaveDetails-cont-introduce3 {
//                     display: flex;
//                     width: 100%;

//                     // border: 1px solid red;

//                     .minkaveDetails-introduce3-left {
//                         width: 68%;

//                         > ul {
//                             width: 100%;
//                             display: flex;
//                             flex-wrap: wrap;
//                             justify-content: space-between;
//                             list-style: none;

//                             li {
//                                 width: 48%;
//                                 height: 90px;
//                                 padding: 5px;
//                                 box-sizing: border-box;
//                                 margin-bottom: 10px;
//                                 border: 1px solid #f7f7f7;

//                                 .survey-left2-title1 {
//                                     font-size: 12px;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }

//                                 .survey-left2-title2 {
//                                     margin-top: 3px;
//                                     font-size: 8px;
//                                     color: #666666;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }

//                                 .survey-left2-footer {
//                                     display: flex;
//                                     margin-top: 6px;
//                                     font-size: 6px;

//                                     > div:nth-child(1) {
//                                         display: none;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }
//                                     > div:nth-child(2) {
//                                         display: flex;
//                                         align-items: center;
//                                         margin-left: 0;
//                                         margin-right: 10px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;

//                                         > img {
//                                             width: 13px;
//                                             height: 9px;
//                                             margin-right: 2px;
//                                         }
//                                     }
//                                     > div:nth-child(3) {
//                                         margin-left: auto;
//                                         display: flex;
//                                         align-items: center;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;

//                                         > img {
//                                             width: 10px;
//                                             height: 9px;
//                                             margin-right: 2px;
//                                         }
//                                     }
//                                 }
//                             }

//                             // 成果转化分页
//                             .pageDown {
//                                 width: 100%;
//                                 margin: 20px auto;
//                                 text-align: start;
//                                 // border: 1px solid blue;
//                             }
//                         }
//                     }

//                     .minkaveDetails-introduce3-right {
//                         margin-left: auto;
//                         width: 30%;
//                     }
//                 }

//                 // 授权视频
//                 .minkaveDetails-cont-introduce4 {
//                     display: flex;
//                     width: 100%;
//                     // border: 1px solid red;

//                     .minkaveDetails-introduce3-left {
//                         width: 68%;

//                         > ul {
//                             width: 100%;
//                             display: flex;
//                             flex-wrap: wrap;
//                             justify-content: space-between;
//                             list-style: none;

//                             li {
//                                 position: relative;
//                                 border: 1px solid #f7f7f7;
//                                 width: 48%;
//                                 height: 160x;
//                                 // margin-bottom: 20px;

//                                 video {
//                                     display: block;
//                                 }

//                                 i {
//                                     // border: 1px solid red;
//                                     position: absolute;
//                                     left: 50%;
//                                     top: 50%;
//                                     transform: translate(-50%, -50%);
//                                     font-size: 30px;
//                                     color: #95bf97;
//                                     cursor: pointer;
//                                     opacity: 0.85;
//                                     &.el-icon-video-pause {
//                                         opacity: 0;
//                                         z-index: 99;
//                                         transition: all 0.4s;
//                                     }
//                                 }
//                                 > div {
//                                     height: 10%;
//                                     padding: 3px 0;
//                                     font-size: 6px;
//                                 }

//                                 &:hover {
//                                     .el-icon-video-pause {
//                                         opacity: 0.85;
//                                     }
//                                 }
//                             }

//                             // 成果转化分页
//                             .pageDown {
//                                 width: 100%;
//                                 margin: 20px auto;
//                                 text-align: start;
//                                 // border: 1px solid blue;
//                             }
//                         }
//                     }

//                     .minkaveDetails-introduce3-right {
//                         margin-left: auto;
//                         width: 30%;
//                     }
//                 }

//                 //难题解答
//                 .minkaveDetails-cont-introduce5 {
//                     width: 100%;
//                     height: 600px;
//                     display: flex;

//                     // 左侧
//                     .minkaveDetails-introduce5-left {
//                         width: 24%;
//                         // height: 216px;
//                         // border: 1px solid red;

//                         // 技术难题
//                         .minkaveDetails-introduce5-title {
//                             > div:nth-child(1) {
//                                 display: flex;
//                                 align-items: center;
//                                 height: 35px;
//                                 line-height: 35px;
//                                 padding: 10px;
//                                 box-sizing: border-box;
//                                 // border: 1px solid red;
//                                 background-color: #0056b0;

//                                 > div:nth-child(1) {
//                                     width: 1.5px;
//                                     height: 16px;
//                                     background: #fff;
//                                 }

//                                 > span {
//                                     margin-top: -1.5px;
//                                     margin-left: 8px;
//                                     font-size: 12px;
//                                     color: #fff;
//                                 }
//                             }

//                             > ul {
//                                 list-style: none;

//                                 .puzzleActive {
//                                     color: #fff;
//                                     background-color: #8fc320;
//                                 }

//                                 li {
//                                     text-align: center;
//                                     height: 35px;
//                                     line-height: 35px;
//                                     border-bottom: 1px solid #f2f2f2;
//                                     letter-spacing: 1px;
//                                     font-size: 12px;
//                                 }
//                             }
//                         }

//                         // 技术难题推荐
//                         .minkaveDetails-introduce5-title2 {
//                             margin-top: 15px;
//                             > div:nth-child(1) {
//                                 font-size: 8px;
//                             }

//                             > ul {
//                                 list-style: none;

//                                 li {
//                                     margin-top: 10px;
//                                     padding: 5px;
//                                     box-sizing: border-box;
//                                     border-radius: 4px;
//                                     font-size: 12px;
//                                     background-color: #f8f8f8;

//                                     > div:nth-child(1) {
//                                         font-size: 8px;
//                                     }

//                                     > div:nth-child(2) {
//                                         margin-top: 4px;
//                                         font-size: 6px;
//                                         color: #9f9f9f;
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 右侧
//                     .minkaveDetails-introduce5-right {
//                         width: 73%;
//                         height: 100%;
//                         margin-left: auto;
//                         // border: 1px solid red;

//                         > ul {
//                             list-style: none;

//                             li {
//                                 box-sizing: border-box;
//                                 margin-bottom: 20px;

//                                 // 上部
//                                 .minkaveDetails-introduce5-top {
//                                     display: flex;
//                                     padding: 3px;
//                                     border: 2px solid #ededed;

//                                     > div:nth-child(1) {
//                                         > div:nth-child(1) {
//                                             font-size: 10px;
//                                         }
//                                         > div:nth-child(2) {
//                                             font-size: 8px;
//                                             margin: 1px 0px;
//                                         }
//                                         > div:nth-child(3) {
//                                             font-size: 8px;
//                                             margin: 1px 0px;
//                                         }
//                                         > div:nth-child(4) {
//                                             font-size: 10px;
//                                         }
//                                         > div:nth-child(5) {
//                                             font-size: 6px;
//                                             margin: 4px 0px;
//                                         }
//                                         > div:nth-child(6) {
//                                             font-size: 10px;
//                                         }
//                                         > div:nth-child(7) {
//                                             font-size: 6px;
//                                             margin: 4px 0px;
//                                         }
//                                         > div:nth-child(8) {
//                                             font-size: 10px;
//                                         }
//                                         > div:nth-child(9) {
//                                             font-size: 6px;
//                                             margin-top: 4px;
//                                             margin-bottom: 2px;
//                                         }
//                                     }
//                                     > div:nth-child(2) {
//                                         height: 18px;
//                                         width: 50px;
//                                         // line-height: 20px;
//                                         padding: 3px 8px;
//                                         box-sizing: border-box;
//                                         margin-left: auto;
//                                         font-size: 6px;
//                                         color: #fff;
//                                         border-radius: 2px;
//                                         background-color: #0056b0;
//                                     }
//                                 }

//                                 // 下部
//                                 .minkaveDetails-introduce5-bottom {
//                                     width: 100%;
//                                     color: #989898;
//                                     // border: 1px solid red;

//                                     > div:nth-child(1) {
//                                         width: 0;
//                                         margin-left: 60px;
//                                         border-left: 6px solid transparent;
//                                         border-right: 6px solid transparent;
//                                         border-bottom: 10px solid #f2f2f2;
//                                         // margin: 50px;
//                                     }

//                                     > div:nth-child(2) {
//                                         padding: 5px;
//                                         box-sizing: border-box;
//                                         font-size: 9px;
//                                         border-radius: 5px;
//                                         background-color: #f2f2f2;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }

//             /*详情页内容区域 end*/
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
